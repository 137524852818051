import { addMonetateCustomVariables } from './pdpUtil';
export var callKibo = function (partNumber, cookies, kiboClubId) {
    // @ts-ignore
    window.monetateQ = window.monetateQ || [];
    // @ts-ignore
    window.monetateQ.push(['setPageType', 'PRODUCT']);
    // @ts-ignore
    window.monetateQ.push(['addProductDetails', [{ productId: partNumber.replace('P_', '') }]]);
    addMonetateCustomVariables(cookies, kiboClubId);
    // @ts-ignore
    monetateQ.push(['trackData']);
};
