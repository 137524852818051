import { Cookies } from 'react-cookie';
export var clearCookie = function (cookieName) {
    if (document) {
        document.cookie = cookieName + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    }
};
export var getProductIdFromPathname = function (pathname) {
    var pathnameArray = pathname === null || pathname === void 0 ? void 0 : pathname.split('/');
    if (pathnameArray[3]) {
        var productId = pathnameArray[3];
        return productId;
    }
    return null;
};
export var removeSpecialCharFromProdName = function (prodName) {
    if (prodName) {
        var productName = prodName
            .trim()
            .replace(/ /g, '-')
            .toLowerCase();
        productName = productName.trim().replace(/[^a-zA-Z_0-9-_ ]/g, '');
        return productName;
    }
    return null;
};
export var getEnvProp = function (propName) {
    if (typeof window === 'undefined') {
        return process.env[propName];
    }
    else {
        //@ts-ignore
        return window['env'] && window.env[propName];
    }
};
export function dateFormat(inputDate, format) {
    //parse the input date
    var date = new Date(inputDate);
    //extract the parts of the date
    var day = date.getDate();
    var month = date.getMonth() + 1;
    var year = date.getFullYear();
    //replace the month
    format = format.replace('MM', month.toString().padStart(2, '0'));
    //replace the year
    if (format.indexOf('yyyy') > -1) {
        format = format.replace('yyyy', year.toString());
    }
    else if (format.indexOf('yy') > -1) {
        format = format.replace('yy', year.toString().substr(2, 2));
    }
    //replace the day
    format = format.replace('dd', day.toString().padStart(2, '0'));
    return format;
}
export function parseCookie(name) {
    var _a, _b;
    if (typeof window !== 'undefined') {
        if (document.cookie) {
            var cookieValue = (_b = (_a = document.cookie) === null || _a === void 0 ? void 0 : _a.split('; ').find(function (row) { return row.startsWith(name); })) === null || _b === void 0 ? void 0 : _b.split('=')[1];
            return cookieValue;
        }
    }
}
export var parseObjectCookie = function (key) {
    var _a;
    if (typeof window !== 'undefined') {
        var cookieObj = (_a = document.cookie) === null || _a === void 0 ? void 0 : _a.split(';').map(function (v) { return v === null || v === void 0 ? void 0 : v.split('='); }).reduce(function (acc, v) {
            var _a, _b;
            acc[decodeURIComponent((_a = v === null || v === void 0 ? void 0 : v[0]) === null || _a === void 0 ? void 0 : _a.trim())] = decodeURIComponent((_b = v === null || v === void 0 ? void 0 : v[1]) === null || _b === void 0 ? void 0 : _b.trim());
            return acc;
        }, {});
        if (cookieObj && cookieObj[key]) {
            return JSON.parse(cookieObj[key]);
        }
    }
};
export var checkRestrictedZipcodes = function (shippingRestrictionValueArray) {
    var _a, _b, _c, _d;
    var shippingRestricted = false;
    if (shippingRestrictionValueArray) {
        if (shippingRestrictionValueArray.length !== 1) {
            var delivery = ((_a = shippingRestrictionValueArray[0]) === null || _a === void 0 ? void 0 : _a.servicetype) === 'D'
                ? (_b = shippingRestrictionValueArray[0]) === null || _b === void 0 ? void 0 : _b.status
                : '';
            var install = ((_c = shippingRestrictionValueArray[1]) === null || _c === void 0 ? void 0 : _c.servicetype) === 'I'
                ? (_d = shippingRestrictionValueArray[1]) === null || _d === void 0 ? void 0 : _d.status
                : '';
            shippingRestricted =
                (delivery === 'true' && install === 'true') || (delivery === 'true' && install === 'false')
                    ? true
                    : false;
        }
        else {
            if (shippingRestrictionValueArray[0].status === 'true' &&
                shippingRestrictionValueArray[0].servicetype === 'D') {
                shippingRestricted = true;
            }
        }
    }
    return shippingRestricted;
};
export var imageExists = function (image_url) {
    var http = new XMLHttpRequest();
    http.open('HEAD', image_url, false);
    http.send();
    return http.status < 400;
};
export var fetchMapPrice = function (hiddenAttr) {
    return hiddenAttr === null || hiddenAttr === void 0 ? void 0 : hiddenAttr.find(function (item) { return item.name == 'ShowPriceCartPostLogin' && item.attributeValueDataBeans[0].value == 'Y'; });
};
export var fetchAlcoholItem = function (hiddenAttr) {
    var _a;
    var alcoholItem = hiddenAttr === null || hiddenAttr === void 0 ? void 0 : hiddenAttr.filter(function (item) { return item.name == 'alcoholItem'; });
    return ((_a = alcoholItem[0]) === null || _a === void 0 ? void 0 : _a.attributeValueDataBeans[0].value) == 'Y';
};
export var getDefaultFulFillmentType = function (queryParam) {
    var fulFillmentType = null;
    var listOfQueryParams = queryParam === null || queryParam === void 0 ? void 0 : queryParam.split('&');
    listOfQueryParams === null || listOfQueryParams === void 0 ? void 0 : listOfQueryParams.forEach(function (param) {
        var _a;
        if (param && param.includes('fulfillment')) {
            fulFillmentType = (_a = param === null || param === void 0 ? void 0 : param.split('=')) === null || _a === void 0 ? void 0 : _a[1];
        }
    });
    return fulFillmentType;
};
export var onClickTracker = function (item) {
    var oImg = document.createElement('img');
    oImg.src = (item === null || item === void 0 ? void 0 : item.click_tracker) || (item === null || item === void 0 ? void 0 : item.ClickTracker);
    oImg.height = 1;
    oImg.width = 1;
    document.body.appendChild(oImg);
};
export var fetchUpdatedCookie = function (cookiename) {
    var cookies = new Cookies();
    return cookies.get(cookiename);
};
export var onImpressionTracker = function (item) {
    var oImg = document.createElement('img');
    oImg.src = (item === null || item === void 0 ? void 0 : item.impression_tracker) || (item === null || item === void 0 ? void 0 : item.ImpressionTracker);
    oImg.height = 1;
    oImg.width = 1;
    document.body.appendChild(oImg);
};
export var isInViewport = function (element) {
    var rect = element === null || element === void 0 ? void 0 : element.getBoundingClientRect();
    return (rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth));
};
export var useMediaQuery = function (query) {
    var getMatches = function (query) {
        // Prevents SSR issues
        if (typeof window !== 'undefined') {
            return window.matchMedia(query).matches;
        }
        return false;
    };
};
export var isBrowser = function () { return typeof window !== 'undefined'; };
export function navigateTo(url) {
    if (typeof window !== 'undefined') {
        window.location.href = url;
    }
}
